import React, { createContext, useContext, useEffect, useState } from 'react';
import de from 'data/de.json';
import en from 'data/en.json';
import fr from 'data/fr.json';
import it from 'data/it.json';

import { Locale } from 'types/types';

const TRANSLATIONS = {
  de,
  en,
  fr,
  it,
};

type Translations = {
  locale: Locale;
  t: typeof TRANSLATIONS.de;
  setLocale: (locale: Locale) => void;
};

const defaultValue: Translations = {
  locale: 'de',
  t: TRANSLATIONS.de,
  setLocale: () => null,
};

const TranslationsContext = createContext(defaultValue);

export const useTranslations = () => useContext(TranslationsContext);

export const TranslationsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [locale, setLocale] = useState(
    (localStorage.getItem('locale') as Locale) ?? defaultValue.locale
  );
  const [translations, setTranslations] = useState(defaultValue.t);

  useEffect(() => {
    localStorage.setItem('locale', locale);
    setTranslations(TRANSLATIONS[locale]);
  }, [locale]);

  return (
    <TranslationsContext.Provider
      value={{
        locale,
        t: translations,
        setLocale: (locale: Locale) => setLocale(locale),
      }}
    >
      {children}
    </TranslationsContext.Provider>
  );
};

export default TranslationsProvider;
