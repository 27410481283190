import Dropdown from './Dropdown';
import { useTranslations } from 'contexts/TranslationsProvider';
import logo from 'assets/logo.svg';

const Header: React.FC = () => {
  const { locale, setLocale } = useTranslations();

  const dropdownOptions = [
    {
      title: 'DE',
      onClick: () => setLocale('de'),
    },
    {
      title: 'EN',
      onClick: () => setLocale('en'),
    },
    {
      title: 'FR',
      onClick: () => setLocale('fr'),
    },
    {
      title: 'IT',
      onClick: () => setLocale('it'),
    },
  ].filter((option) => option.title !== locale.toUpperCase());

  return (
    <div className="flex justify-between items-center w-full py-6">
      <img className="h-[75px]" src={logo} alt="" />
      <Dropdown value={locale.toUpperCase()} options={dropdownOptions} />
    </div>
  );
};

export default Header;
