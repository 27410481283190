import { useEffect } from 'react';
import Header from 'components/Header';

import {
  APP_URL_SCHEME,
  PDP_AUCTION_PATH,
  PDP_ARTICLE_PATH,
} from 'constants/constants';
import { useTranslations } from 'contexts/TranslationsProvider';

import appIcon from 'assets/app-icon.png';
import qrCode from 'assets/qrcode.svg';
import appStoreLogo from 'assets/appstore.svg';
import googlePlayLogo from 'assets/googleplay.svg';

const App: React.FC = () => {
  const { t } = useTranslations();

  useEffect(() => {
    const { pathname } = window.location;

    if (
      pathname.includes(PDP_AUCTION_PATH) ||
      pathname.includes(PDP_ARTICLE_PATH) ||
      pathname === '/'
    ) {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        window.location.href = APP_URL_SCHEME + ':/' + pathname.split('?')[0];
      }
    }
  }, []);

  return (
    <div className="container mx-auto px-6 pb-20 sm:px-0">
      <Header />

      <div className="mt-[50px] sm:px-6">
        <h1 className="text-2xl font-semibold md:text-5xl">
          {t.website.website_title}
        </h1>
        <p className="mt-3 text-sm text-gray-tint md:text-2xl md:mt-6">
          {t.website.website_description}
        </p>
      </div>

      <div className="mt-12 flex items-center justify-center md:mt-[96px] md:justify-between">
        <div className="flex flex-col md:flex-row items-center">
          <img
            className="mb-10 w-[216px] h-[216px] md:mb-0 md:w-[146px] md:h-[146px]"
            src={appIcon}
            style={{ filter: 'drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.24))' }}
            alt=""
          />
          <div className="text-center md:text-left md:ml-9">
            <h3 className="text-2xl font-semibold md:text-4xl">
              {t.website.website_app_title}
            </h3>
            <p className="mt-2 text-gray-tint md:text-2xl">
              {t.website.website_app_description}
            </p>

            <div className="flex mt-4 items-center md:justify-start">
              <a href={t.website.website_app_link_appstore}>
                <img
                  className="w-[124px] sm:w-[145px] md:w-[178px]"
                  src={appStoreLogo}
                  alt=""
                />
              </a>
              <a href={t.website.website_app_link_googleplay}>
                <img
                  className="w-[124px] sm:w-[145px] md:w-[178px] ml-4 md:ml-6"
                  src={googlePlayLogo}
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>

        <img
          className="w-[146px] h-[146px] hidden md:inline lg:w-[320px] lg:h-[320px]"
          src={qrCode}
          alt=""
        />
      </div>
    </div>
  );
};

export default App;
