import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import cx from 'classnames';

type Props = {
  options: { title: string; onClick?: () => void }[];
  value: string;
};

const Dropdown: React.FC<Props> = ({ options, value }) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex items-center focus:outline-none">
          <div className="text-2xl text-gray">{value}</div>
          <ChevronDownIcon className="ml-1 h-6 text-gray" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 -mt-1 rounded-md shadow-lg bg-white focus:outline-none focus:border-none">
          <div>
            {options.map((el) => (
              <Menu.Item key={el.title}>
                {({ active }: { active: boolean }) => (
                  <div
                    className={cx('block px-4 py-2 text-lg cursor-pointer', {
                      'bg-gray-100 text-gray-900': active,
                      'text-gray-700': !active,
                    })}
                    onClick={el.onClick}
                  >
                    {el.title}
                  </div>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default Dropdown;
